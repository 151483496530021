import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,InputNumber,Checkbox,Popconfirm,Popover,DatePicker,Table,Modal } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined,CloseCircleFilled,PlusCircleFilled,FundViewOutlined,MailOutlined,UploadOutlined,PrinterOutlined,RightCircleOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';

import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var sacco= ''
var token= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
    loantypes:[],

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/loantypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          loantypes:res.data
        })
    })

  }

  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>

                <Col xs="2" sm="2" lg="2">
                  <div style={{display:'flex',width:150,height:150,border: '1px solid black',justifyContent:'center', alignItems:'center',}}>
                    <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder',alignSelf:'center'}}>Passport</h3>

                  </div>

                </Col>
     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN APPLICATION FORM</h3>


            <h3 style={{display: 'flex',fontWeight:'bold'}}>1. Select Applied Loan Type</h3>
            <h4>{this.state.loantypes.map((item)=>(<div><RightCircleOutlined />&nbsp;{item.loan_type_name} &nbsp;&nbsp;  <Checkbox /> </div>))}</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>2. Applicant Account Name.</h3>
            <h4>.........................................,..................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>3. Applicant Account Number.</h3>
            <h4>.........................................,..................................................................................................................................</h4>


            <h3 style={{display: 'flex',fontWeight:'bold'}}>4. Applicant Savings Balance.</h3>
            <h4>............................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>5. Applicant Number Of Shares.</h3>
            <h4>.................................................................................................................,..........................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>6. Applicant Previous Loan Balance.</h3>
            <h4>.............................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>7. Applicant Loan Request Amount.</h3>
            <h4>.............................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>8. Applicant Loan Purpose.</h3>
            <h4>..............................................................................................................................................................................</h4>

                 <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOCATION DETAILS</h2>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>9. Applicant Location.</h3>
            <h4>..............................................................................................................................................................................</h4>


            <h3 style={{display: 'flex',fontWeight:'bold'}}>10. Own Premises?</h3>
            <h4> <p>Yes &nbsp;&nbsp;  <Checkbox />  No &nbsp;&nbsp;  <Checkbox /> </p></h4>

                         
                         <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>BUSINESS DETAILS</h2>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>11. Applicant Business Type.</h3>
            <h4>...............................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>12. Legal status of Business.</h3>
            <h4> <p>Registered &nbsp;&nbsp;  <Checkbox />  UnRegistered &nbsp;&nbsp;  <Checkbox /> </p></h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>13. Duration in business.</h3>
            <h4>..................................................................................................................................................................................</h4>
                          
                          <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>COLLATERAL DETAILS</h2>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>14. Collateral one.</h3>
            <h4>.................................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>15. Collateral one value.</h3>
            <h4>..................................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>16. Collateral two.</h3>
            <h4>...................................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>17. Collateral two value.</h3>
            <h4>.....................................................................................................................................................................................</h4>

            <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>GUARANTOR DETAILS</h2>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>18. Guarantor one name.</h3>
            <h4>.....................................................................................................................................................................................</h4>
 
            <h3 style={{display: 'flex',fontWeight:'bold'}}>19. Guarantor one contact.</h3>
            <h4>......................................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>20. Guarantor one Signature.</h3>
            <h4>......................................................................................................................................................................................</h4>


            <h3 style={{display: 'flex',fontWeight:'bold'}}>21. Guarantor two name.</h3>
            <h4>......................................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>22. Guarantor two contact.</h3>
            <h4>......................................................................................................................................................................................</h4>

            <h3 style={{display: 'flex',fontWeight:'bold'}}>23. Guarantor two Signature.</h3>
            <h4>......................................................................................................................................................................................</h4>

            <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>DECLARATION</h2>

            <h4>
              I declare that the information I have given on this form is to the best of my knowledge and belief, accurate and full information. I understand that the provision of false
              information is fraud and the saving and credit scheme may take appropriate action if Iam found to have deliberately provided false or misleading information.
            </h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>NAME OF APPLICANT</th>
                          <th>SIGNATURE</th>
                          <th>DATE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                </reactstrp.Table>
             <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>OFFICIAL USER ONLY</h2>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>LOAN OFFICER</th>
                          <th>SIGNATURE</th>
                          <th>DATE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>

                      </thead>
                </reactstrp.Table>
                <h3 style={{display: 'flex',fontWeight:'bold'}}>Comment:
                  ...................................................................................................................................................
                  ...................................................................................................................................................
                  ...................................................................................................................................................
                </h3>

                <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>LOAN COMMITTEE MEMBER</th>
                          <th>SIGNATURE</th>
                          <th>DATE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>

                      </thead>
                </reactstrp.Table>
                <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>LOAN COMMITTEE MEMBER</th>
                          <th>SIGNATURE</th>
                          <th>DATE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>

                      </thead>
                </reactstrp.Table>
                <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>LOAN COMMITTEE MEMBER</th>
                          <th>SIGNATURE</th>
                          <th>DATE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>

                      </thead>
                </reactstrp.Table>
                <h3 style={{display: 'flex',fontWeight:'bold'}}>Comment:
                  .......................................................................................................................................................
                  .......................................................................................................................................................
                  .......................................................................................................................................................
                  .......................................................................................................................................................
                  .......................................................................................................................................................

                </h3>

                <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>BRANCH MANAGER</th>
                          <th>SIGNATURE</th>
                          <th>DATE</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>

                      </thead>
                </reactstrp.Table>
                <h3 style={{display: 'flex',fontWeight:'bold'}}>Comment:
                  ...................................................................................................................................................
                  ...................................................................................................................................................
                  ...................................................................................................................................................
                </h3>
      </div>
    );
  }
}




class LoanApplicationForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    loan_type:'',
    account:'',
    savings_balance:0,
    loan_balance:0,
    loan_amount:0,
    loan_purpose:'',
    business_type:'',
    applicant_location:'',
    own_premises:'',
    legal_status_of_business:'',
    duration_in_business:'',
    collateral_one:'',
    collateral_one_value:0,
    collateral_two:'',
    collateral_two_value:0,
    loantypes:[],
    members:[],
    loantypeobj:{},
    accountobj:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    loanapplications:[],

    othercol_model_visible:false,
    othercol_details:'',
    othercol_value:'',
    othercollateral_list:[],
    availableloans:[],
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(serverconfig.backendserverurl+`/api/loantypes/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loantypes:res.data
          })
      })
  
      axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            members:res.data
          })
  
      })

      axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loanapplications:res.data
          })
  
      })


      axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
      .then(res => {
          this.setState({
            saccoboject:res.data
          })
  
          //get the sacco package here
          axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
          .then(res => {
              this.setState({
                saccopackageobj:res.data
              })
  
              this.setState({datarequested:false})
  
          })
  
      })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }


  //calculate percentage loan limit against 
  calculate_loanlimit=()=>{
    var limit =0

    var xx_var=0
    var coll_to=0

    if (this.isEmptyObject(this.state.loantypeobj)===false){
      xx_var=Number(this.state.loantypeobj.no_of_times_collateral_againstloanamount)

      if (this.state.othercollateral_list.length>0){
        this.state.othercollateral_list.map((item)=>{
          coll_to+=Number(item.othercol_value)
        })
        limit=((coll_to)*(1/xx_var)).toFixed(2)    

      }


    }


    return limit;
  }


  //submit button pressed
  handleFormSubmit=(event) =>{


    if (Number(this.state.loan_amount)> Number(this.state.loantypeobj.maximum_loan_amount)){
      message.error("Entered Loan Amount should be less or equal to "+String(this.state.loantypeobj.maximum_loan_amount))
  }else if (this.state.othercollateral_list.length<=0){
    message.error("Collateral list cant be less or equal zero")
  }

  else if (Number(this.state.loan_amount)>this.calculate_loanlimit()){
    message.error("Loan amount cant go beyond the limit."+String(this.calculate_loanlimit()))
  }else if(this.state.availableloans.length>0&&Number(this.state.loan_type)===Number(this.state.availableloans.slice(-1)[0].loantype))
    message.error("You cant re apply for the same loan product that is currently active")

  else{



    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_type', this.state.loan_type);
    form_data.append('account', this.state.account);
    form_data.append('savings_balance', this.state.savings_balance);
    form_data.append('loan_balance', this.state.loan_balance);
    form_data.append('loan_amount', this.state.loan_amount);
    form_data.append('loan_purpose', this.state.loan_purpose);
    form_data.append('business_type', this.state.business_type);
    form_data.append('applicant_location', this.state.applicant_location);
    form_data.append('own_premises', this.state.own_premises);
    form_data.append('legal_status_of_business', this.state.legal_status_of_business);
    form_data.append('duration_in_business', this.state.duration_in_business);
    form_data.append('sacco', sacco);

    form_data.append('othercollateral_list',JSON.stringify(this.state.othercollateral_list));
    form_data.append('application_loan_limit', this.calculate_loanlimit());
    
    
    axios.post(serverconfig.backendserverurl+'/api/loanapplications/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         
    this.setState({datarequested:false})
,  this.setState({datasubmittedsuccessfully:true})
  )
  .catch(error => console.log(error))

  }

  }

  handleloan_typeChange= (e) => {
    this.setState({ loan_type: e});
    //get loan type details
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/loantypes/${e}`)
      .then(res => {  
          this.setState({loantypeobj: res.data})
      })
  }



  handleaccountChange= (e) =>{
    this.setState({ account: e});
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accountobj: res.data})
          this.setState({savings_balance:res.data.UserBalance})
      })

      //check the last loan attributes
      let form_data = new FormData();
      form_data.append('account_id', e);

      axios.post(serverconfig.backendserverurl+'/customqueries/getlastloanattributes', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
          this.setState({loan_balance:res.data.user_info.totalLoanBalance})
        
      })
      .catch(error => console.log(error))

      //get available loans
      axios.get(`${serverconfig.backendserverurl}/customqueries/get_memberactiveloans/${e}`)
      .then(res => {  
          this.setState({availableloans: JSON.parse(res.data.report)})
      })

  } 
  
  
  handlesavings_balanceChange= (e) => this.setState({ savings_balance: e.target.value});
  handleloan_balanceChange= (e) => this.setState({ loan_balance: e.target.value});
  handleloan_amountChange= (e) => this.setState({ loan_amount: e});
  handleloan_purposeChange= (e) => this.setState({ loan_purpose: e.target.value});
  handlebusiness_typeChange= (e) => this.setState({ business_type: e.target.value});
  handleapplicant_locationChange= (e) => this.setState({ applicant_location: e.target.value});
  handleown_premisesChange= (e) => this.setState({ own_premises: e});
  handlelegal_status_of_businessChange= (e) => this.setState({ legal_status_of_business: e});
  handleduration_in_businessChange= (e) => this.setState({ duration_in_business: e.target.value});

  render(){

    var columns = [
      {
        title: 'Loan Number',
        dataIndex: 'loan_number',
        key: 'id',
      },
      {
        title: 'Loan type',
        dataIndex: 'loantypename',
        key: 'id',
      },
      {
        title: 'Loan Amount',
        dataIndex: 'approved_amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Issuance Date',
        dataIndex: 'date',
        key: 'id',
      },
      {
        title: 'Loan Period',
        dataIndex: 'loan_period',
        key: 'id',
        render:(text,record)=><h5>{text} ({record.loan_primary_schedule})</h5>
      },
      
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to view details(Schedule & Statement)</p>} title="View">
         <Link to={`/loanissues/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>         
        </p>,
      }

    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Submitted loan Application "
            subTitle="Application will be ready for asessment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{

        if(this.state.loanapplications.length>=this.state.saccopackageobj.loanslimit){
          return (
            <div>
              <h4 style={{color:'red'}}>You have reached loans limit for this package.Please contact adminstrators for upgrade of package.Thanx</h4>
            </div>
          );

        }else{

          return (
            <div>
                <div style={{display: "none"}}>
                  &nbsp;&nbsp;
                  <ReportToPrint
                  ref={el => (this.componentRef = el)} /> 
                </div>

                <Row >
                      <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN APPLICATION FORM</h4>
                        <Form
                          onFinish={(event) => this.handleFormSubmit(event)} 
                        >

                      <FormItem label="Date"
                            name='date'
                            rules={[
                              {
                                required: true,
                                message: 'Please Select date',
                              },
                            ]}
                          >
                              <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                          </FormItem>
    
                        <FormItem label="Loan Type"
                            name="loantype"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Type',
                              },
                            ]}           
                          >
                              <Select 
                              placeholder="Loan Type" 
                              style={{  }} 
                              value={this.state.loan_type} 
                              onChange={this.handleloan_typeChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              
                              >
                                {this.state.loantypes.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.loan_type_name}</Option>
                                  ))}
                              </Select>
                          </FormItem>
    
    
                          <FormItem label="Account"
                            name="account"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Account',
                              },
                            ]}           
                          >
                              <Select 
                              placeholder="Account" 
                              style={{  }} value={this.state.account} 
                              onChange={this.handleaccountChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              >
                                {this.state.members.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                                  ))}
                              </Select>
                          </FormItem>
    
                
                          <FormItem label="Loan Purpose"
                            name="loan_purpose"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter purpose',
                              },
                            ]}           
                          >
                            <Input name="loan_purpose"  placeholder="Purpose" value={this.state.loan_purpose} onChange={this.handleloan_purposeChange} />
                          </FormItem>
    
                          <FormItem label="Business Type"
                            name="business_type"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter type',
                              },
                            ]}           
                          >
                            <Input name="business_type"  placeholder="Type" value={this.state.business_type} onChange={this.handlebusiness_typeChange} />
                          </FormItem>
    
    
    
                          <FormItem label="Applicant Location"
                            name="applicant_location"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter location',
                              },
                            ]}           
                          >
                            <Input name="applicant_location"  placeholder="Location" value={this.state.applicant_location} onChange={this.handleapplicant_locationChange} />
                          </FormItem>
    
                          <FormItem label="Own Premises?"
                            name="own_premises"
                            rules={[
                              {
                                required: true,
                                message: 'Please select',
                              },
                            ]}           
                          
                          >
                              <Select placeholder="Own Premises" style={{ width: 120 }} value={this.state.own_premises} onChange={this.handleown_premisesChange} >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                              </Select>
                          </FormItem>
    
                          <FormItem label="Legal Status Of Business"
                            name="legal_status_of_business"
                            rules={[
                              {
                                required: true,
                                message: 'Please select',
                              },
                            ]}           
                          
                          >
                              <Select placeholder="Legal Status" style={{ width: 120 }} value={this.state.legal_status_of_business} onChange={this.handlelegal_status_of_businessChange} >
                                    <Option value="Registered">Registered</Option>
                                    <Option value="UnRegistered">UnRegistered</Option>
                              </Select>
                          </FormItem>
    
                          <FormItem label="Duration In Business"
                            name="duration_in_business"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter duration',
                              },
                            ]}           
                          >
                            <Input name="duration_in_business"  placeholder="Location" value={this.state.duration_in_business} onChange={this.handleduration_in_businessChange} />
                          </FormItem>
    
    
                          <FormItem label=  {<h3>Click to add collaterals</h3>}>
                          <PlusCircleFilled 
                          style={{fontSize: '35px',margin:2,color:'#9B116F'}}
                          onClick={()=>{
                            this.setState({othercol_model_visible:true})
                          }}
                          />
                          </FormItem>

                          {
                            this.state.othercollateral_list.length>0?
                            <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Collateral details</th>
                                <th>Value</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.othercollateral_list.map(
                              (item)=>(
                                <tr>
                                <td>{item.othercol_details}</td>
                                <td><CurrencyFormat value={item.othercol_value} displayType={'text'} thousandSeparator={true}  /></td>

                                <td>
                                <Popover content={<p>Click here to delete item</p>} title="Delete Item">
                                <Popconfirm title="Sure to delete?" onConfirm={() => {
                                  this.setState({ guarantorslist:  [...this.state.guarantorslist.filter(todo => todo.key
                                    !==item.key)]});
                                }}>
                                <CloseCircleFilled style={{color:'red'}}  />
                                </Popconfirm>       
                                </Popover>
                                </td>
                                </tr>
                              ))}
                          </tbody>
                          </reactstrp.Table>  
                          :
                          null
                        }


                          <FormItem label={<h3>Request Amount</h3>} 
                            name="loan_amount"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values',
                              },
                            ]}           
                          >
    
                          <InputNumber
                            style={{ width: 200 }} 
                            defaultValue={0.0}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\x\s?|(,*)/g, '')}
                            onChange={this.handleloan_amountChange}
                          />
    
                          </FormItem>
                        
                        <h3>Loan limit: <CurrencyFormat value={this.calculate_loanlimit()} displayType={'text'} thousandSeparator={true}  /></h3>

    
                          <FormItem>
                            <Button  type="primary" htmlType="submit">Create</Button>
                          </FormItem>
                        </Form>
    
                        </Card>
                        </Col>
    
    
                        <Col xs="12" sm="6" lg="6">
                        <Card>
                        {
                          this.state.availableloans.length>0?
                          <div>
                          <br></br>
                          <h3 style={{color:'green'}}>Previous loans</h3>
                          <Table   
                            columns={columns} 
                            scroll={{ x: 1000 }}
                            dataSource={this.state.availableloans} 
                            pagination={{showQuickJumper:true,showSizeChanger:true }}
                            bordered/>
                          <br />
                          </div>
                          :
                          null
                        }

                      
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account Details</h4>
                        <h6>Account Name: {this.state.accountobj.FullName}</h6>
                        <h6>Account Number: {this.state.accountobj.AccountNumber}</h6>
                        <h6>Date of joining: {this.state.accountobj.DateOfJoining}</h6>
                        <h6>Savings Balance: <CurrencyFormat value={this.state.accountobj.UserBalance} displayType={'text'} thousandSeparator={true}  /></h6>
                        <h6>Loan Balance: <CurrencyFormat value={this.state.accountobj.loan_balance} displayType={'text'} thousandSeparator={true}  /></h6>

                        <h6>Shares number: {this.state.accountobj.sharesnumber_balance}</h6>
                        <h6>Shares amount:<CurrencyFormat value={this.state.accountobj.sharesamount_balance} displayType={'text'} thousandSeparator={true}  /> </h6>
                        <h6>Savings loan security balance:<CurrencyFormat value={this.state.accountobj.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h6>
                        <h6>Shares loan security balance:<CurrencyFormat value={this.state.accountobj.shares_security_balance} displayType={'text'} thousandSeparator={true}  /> </h6>
                        <h6>Loan fine balance:<CurrencyFormat value={this.state.accountobj.loanfine_balance} displayType={'text'} thousandSeparator={true}  /> </h6>



                        <h3>Print Application Form</h3>
                        <ReactToPrint
                          trigger={() => 
                          <Button type="primary"  shape="round" icon={<PrinterOutlined />} >
                            Print Loan Application Form
                          </Button>
                          }
                          content={() => this.componentRef}
                          />

                        </Card>
                        </Col>
                </Row>



                <Modal              
              visible={this.state.othercol_model_visible}
              title={" Collateral Form"}
              onCancel={()=>{this.setState({othercol_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({othercol_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                        var intobj={
                          'key':uuid(),
                          'othercol_details':this.state.othercol_details,
                          'othercol_value':this.state.othercol_value,
                        }

                        this.setState({othercollateral_list: [...this.state.othercollateral_list, intobj]});

                        this.setState({othercol_model_visible:false})

                        this.setState({othercol_details:''})
                        this.setState({othercol_value:0})

                    }}>
                  Add Collateral
                </Button>
                ]}
                >
                  
                <FormItem label="Colateral Details" >
                    <Input name="othercol_details"  placeholder="colateral details" value={this.state.othercol_details} onChange={(val)=>{this.setState({othercol_details:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Collateral Value" >
                    <Input name="othercol_value"  placeholder="Collateral Value" value={this.state.othercol_value} onChange={(val)=>{this.setState({othercol_value:val.target.value})}} type='number' />
                </FormItem> 
               </Modal> 

    
            </div>
          );

        }

      }

     
    }


    
  }

}


export default LoanApplicationForm;



